import { readonly } from "vue"

const dataSourceTypes = [
  { value: "settings", label: "Settings (Internal)" },
  { value: "api", label: "API (External)" }
]

const alignTypes = [
  { value: "default", label: "default" },
  { value: "left", label: "left" },
  { value: "center", label: "center" },
  { value: "right", label: "right" },
]

const componentTypes = [
  { value: "form", label: "Form" },
  { value: "table", label: "Table" }
]

const componentStatuses = [
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" }
]

const componentToggleOptions = [
  { value: "open", label: "Open" },
  { value: "closed", label: "Closed" }
]

const filterTypes = [
  { value: "routeId", label: "Route ID" },
  { value: "routeData", label: "Route Data" },
  { value: "customValue", label: "Custom Value" },
  { value: "manyValues", label: "Many Values" },
  { value: "parentRenderId", label: "Parent Render ID" },
]

const operatorTypes = [
  { value: '=', label: 'Equal' },
  { value: '!=', label: 'Not equal' },
  { value: 'like', label: 'Contains' },
  { value: 'not_like', label: 'Does not contain' },
  { value: 'where_in', label: 'Where In'},
  { value: '>', label: 'Greater then' },
  { value: '<', label: 'Lesser then' },
  { value: '<>', label: 'Between' },
  { value: 'array_contains', label: 'Array contains'},
  { value: 'is_null', label: 'Is null' }
]

const toolbarStatuses = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
]

const sections = [ 
  { 
    name: 'general', 
    items: [ 
      { name: "name", type: "text", label: "Name" }, 
      { name: "type", type: "select", label: "Type", options: componentTypes },
      { name: "endpoint", type: "text", label: "API Endpoint" },
      { name: "route", type: "text", label: "Application Route" },
      { name: "title", type: "text", label: "Title" },
      { name: "status", type: "select", label: "Status", options: componentStatuses },
      { name: "toggle", type: "select", label: "Toggle", options: componentToggleOptions },
    ] 
  },
  { 
    name: 'columns', 
    structure: [ 
      { 
        name: "thead",
        items: [
          { name: "field", label: "Field", align: "" },
          { name: "type", label: "Type", align: "" },
          { name: "title", label: "Title", align: "" },
          { name: "align", label: "Align", align: "" },
          { name: "actions", label: "Actions", align: "right", items: [ { label: "Delete", icon: "delete" } ] }
        ]
      }, 
    ] 
  },
  { 
    name: 'filters', 
    structure: [ 
      { 
        name: "thead",
        items: [
          { name: "type", label: "Type", align: "" },
          { name: "field", label: "Field", align: "" },
          { name: "operator", label: "Operator", align: "" },
          { name: "value", label: "Value", align: "" },
          { name: "actions", label: "Actions", align: "right", items: [ { label: "Delete", icon: "delete" } ] }
        ]
      }, 
    ] 
  },
  { 
    name: 'actions', 
    items: [ 
      { name: "status", type: "select", label: "Status", options: toolbarStatuses }, 
    ] 
  },
] as any

const columnTableTemplate = {
  type: '',
  align: '',
  field: '',
  title: '',
  width: '',
  settings: '',
}

const componentFiltersTemplate = {
  type: '',
  field: '',
  operator: '',
  value: '',
}

const columnSettingsTemplate = [
  { name: 'status', 
    conditionals: [ {
        watchField: 'dataSource',
        targetField: 'route',
        result: 'show',
        value: 'api'
      },
      { 
        watchField: 'dataSource',
        targetField: 'routeParam',
        result: 'show',
        value: 'api'
      },
      { 
        watchField: 'dataSource',
        targetField: 'routeName',
        result: 'show',
        value: 'api'
      },
      { 
        watchField: 'dataSource',
        targetField: 'routeName',
        result: 'show',
        value: 'settings'
      }
    ],
    fields: [ 
    { name: 'dataSource', label: 'Data Source', type: 'select', options: dataSourceTypes }, 
    { name: 'route', label: 'Route', type: 'text' }, 
    { name: 'routeParam', label: 'Route Param', type: 'text' },
    { name: 'routeName', label: 'Route Name', type: 'text' }, 
  ] }
]


const columnTypes = [ 
  { value: 'text', label: 'text', settings: false },
  { value: 'textShort', label: 'textShort', settings: false },
  { value: 'status', label: 'status', settings: { state: true }},
  //{ value: 'statusReturn', text: 'statusReturn' },
  { value: 'statusBoolean', label: 'statusBoolean', settings: false },
  { value: 'simpleLink', label: 'simpleLink', settings: false },
  { value: 'simpleLinkLanguage', label: 'simpleLinkLanguage', settings: false },
  { value: 'countryFlag', label: 'countryFlag', settings: false },
  { value: 'smallImage', label: 'smallImage', settings: false },
  { value: 'iconSvg', label: 'iconSvg', settings: false },
  { value: 'thumbnail', label: 'thumbnail', settings: false },
  { value: 'editButton', label: 'editButton', settings: false },
  { value: 'modalButton', label: 'modalButton', settings: false },
  { value: 'dateTime', label: 'dateTime', settings: false },
  { value: 'textTranslation', label: 'textTranslation', settings: false },
  { value: 'inputText', label: 'inputText', settings: false },
  { value: 'pdfView', label: 'pdfView', settings: false },
  { value: 'badgeOptions', label: 'badgeOptions', settings: false },
  { value: 'invoiceEmail', label: 'invoiceEmail', settings: false },
  { value: 'maxCompare', label: 'maxCompare', settings: false },
  { value: 'changeInlineBoolean', label: 'changeInlineBoolean', settings: false}
]

export { sections, columnTypes, alignTypes, columnSettingsTemplate, columnTableTemplate, componentFiltersTemplate, filterTypes, operatorTypes };